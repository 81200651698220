<template>
    <div>
        <!-- vant导航栏 -->

        <!-- 上边框 -->
        <van-row type="flex" justify="center" style="margin: 1rem">
            <van-image
                    round
                    cover
                    width="5rem"
                    height="5rem"
                    :src="require('@/pic/pic.jpg')"
            />
        </van-row>

        <!-- vant表单 -->
        <van-form>
            <van-field
                    v-model="username"
                    name="口令"
                    label="口令"
                    placeholder="请填写专用口令"
                    :rules="[{ required: true, message: '请填写正确的口令' }]"
            />
<!--            <van-field-->
<!--                    v-model="password"-->
<!--                    :type="isPassword"-->
<!--                    name="密码"-->
<!--                    label="密码"-->
<!--                    placeholder="密码"-->
<!--                    :rules="[{ required: true, message: '请填写密码' },{pattern: /^\w{6,}$/,message:'密码不少于6位'}]"-->
<!--            >-->
<!--                <template #right-icon>-->
<!--                    <span @click="onPassword" style="font-size: 20px;" class="iconfont icon-view"></span>-->
<!--                </template>-->
<!--            </van-field>-->

            <div style="margin: 16px;">
                <van-button @click="onSubmit" round block type="info" native-type="submit">
                    登录
                </van-button>
            </div>
        </van-form>

<!--        <div style="float: right;">-->
<!--            <router-link to="/reg">没有账号？点击注册</router-link>-->
<!--        </div>-->

    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            isPassword:'password', //密码的type类型
            isText:true
        };
    },
    methods: {
        onSubmit(){ //点击登录
            //如果不符合登录条件则不会继续执行
            if(this.username.trim() == ''){
                return
            }
            // if(!this.username.match(/^[1][3,5,6,7,8,9][0-9]{9}$/)){
            //     return
            // }
            // if(!this.password.match(/^\w{6,}$/)){
            //     return
            // }

            //  登录并放入localStorage


            // let id = this.$route.query.username
            // 获取数据
            let users = localStorage.users
            if(users){
                users = JSON.parse(users)
                let isLogin = false
                users.map(item=>{
                    if(item.username == this.username && item.password == this.password){
                        isLogin = true
                        return
                    }
                })

                if(isLogin){
                    sessionStorage.user = this.username
                    this.$router.push({
                        path:'/wms'
                    })
                }else{
                    this.$notify({ type: 'danger', message: '输入的账号或密码有误' });
                }

            }else{
                this.$notify({ type: 'danger', message: '该用户不存在' });
            }
        },
        onPassword(){ //点击切换密码的type类型
            this.isText = !this.isText
            if(this.isText){
                this.isPassword = 'password'
            }else{
                this.isPassword = 'text'
            }
        },
        back(){ //返回我的页面
            this.$router.push({
                path:'/user'
            })
        }
    }

};
</script>

<style>
</style>